//#region IMPORTS
import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { MyField, MyTextArea } from "../components/formikExtensions";
import axios from "axios";
import "../styles/rsvp.css";
import "../styles/item-list.css";

//#endregion

const validate = (values) => {
  const errors = {};

  if (values.attending !== "yes" && values.attending !== "no") {
    errors.attending = "empty";
    return errors;
  }

  if (values.attending === "yes") {
    if (!values.guestCount || values.guestCount < 1)
      errors.guestCount = "Fyll i antal";
  }

  if (!values.firstNames || values.firstNames.trim() === "")
    errors.firstNames = "Ange förnamn";

  return errors;
};

export default function RSVP() {
  const [attending, setAttending] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onAttendingChange = (e) => {
    setAttending(e.target.value);
  };

  return (
    <div id="rsvp-wrapper">
      <h1>Rsvp - anmälan</h1>
      <p>
        18:e januari 2025, kl 13:00 - 19:00
        <br />
        Jädersgården, Eskilstuna
      </p>
      <Formik
        initialValues={{
          attending: "",
          guestCount: 1,
          firstNames: "",
          dietary: "",
          other: "",
        }}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          axios
            .post("/api/rsvp", values)
            .then(function (response) {
              console.log(response);
              setSubmitting(false);
              setFormSubmitted(true);
            })
            .catch(function (error) {
              console.log(error);
              setSubmitting(false);
            });
        }}
      >
        <Form>
          <div>
            <p className="no-margin-bottom">Vi:</p>
            <label>
              <Field
                name="attending"
                value="yes"
                type="radio"
                onClick={onAttendingChange}
                disabled={formSubmitted}
              />
              <span>kommer jättegärna till bröllopsfesten</span>
            </label>
            <label>
              <Field
                name="attending"
                value="no"
                type="radio"
                onClick={onAttendingChange}
                disabled={formSubmitted}
              />
              <span>kan tyvärr inte komma</span>
            </label>
            <ErrorMessage name="attending" />
          </div>

          {attending !== "no" && (
            <MyField
              name="guestCount"
              type="number"
              label="Antal gäster"
              disabled={formSubmitted}
            />
          )}

          <MyTextArea
            name="firstNames"
            label={"Förnamn på " + (attending === "no" ? "personer" : "gäster")}
            disabled={formSubmitted}
          />

          {attending !== "no" && (
            <MyTextArea
              name="dietary"
              label="Allergier och dietära restriktioner, med antal (valfri)"
              disabled={formSubmitted}
            />
          )}

          <MyTextArea
            name="other"
            label={
              attending === "no"
                ? "Vi är ledsna att ni inte kan komma, skulle ni vilja lämna ett meddelande åt brudparet? (valfri)"
                : "Övriga upplysningar (valfri)"
            }
            disabled={formSubmitted}
          />

          <button type="submit" disabled={formSubmitted}>
            Skicka
          </button>
          {formSubmitted && <p>Tack för din anmälan!</p>}
        </Form>
      </Formik>
    </div>
  );
}
