import React from "react";
import * as RadixDialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import "../styles/react-dialog.css";

export default function Dialog({
  triggerButtonText,
  triggerButtonClassName,
  title,
  description,
  content,
  buttons,
  closeButton,
}) {
  return (
    <RadixDialog.Root>
      <RadixDialog.Trigger asChild>
        <button
          className={`dialog_trigger-button${
            triggerButtonClassName && " " + triggerButtonClassName
          }`}
        >
          {triggerButtonText}
        </button>
      </RadixDialog.Trigger>
      <RadixDialog.Portal>
        <RadixDialog.Overlay className="dialog_overlay" />
        <RadixDialog.Content className="dialog_content">
          {title && (
            <RadixDialog.Title className="dialog_title">
              {triggerButtonText}
            </RadixDialog.Title>
          )}
          {description && (
            <RadixDialog.Description className="dialog_description">
              {description}
            </RadixDialog.Description>
          )}
          {content}
          <div
            className={`dialog_button-container ${
              buttons.length === 1 ? "one-button" : "multiple-buttons"
            }`}
          >
            <div className="dialog_button-inner-container">
              {buttons.map((button) => {
                return (
                  <RadixDialog.Close asChild>
                    <button
                      className={`dialog_button${
                        button.className && " " + button.className
                      }`}
                      onClick={button.onClick}
                    >
                      {button.text}
                    </button>
                  </RadixDialog.Close>
                );
              })}
            </div>
          </div>

          {closeButton && (
            <RadixDialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <Cross2Icon />
              </button>
            </RadixDialog.Close>
          )}
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}
