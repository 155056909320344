//#region IMPORTS
import React from "react";
import ItemList from "../components/item-list";

//#endregion

export default function Registry() {
  return (
    <div id="registry-wrapper">
      <p>
        Vad kul att ni vill komma till vår vintriga bröllopsfest. Att ta med
        presenter är så klart valfritt, om ni vill ge oss något så har vi satt
        ihop en lista med presentförslag. Listan är ännu inte fullständig och
        kommer växa med tiden.
      </p>
      <p>
        Klicka på de olika förslagen för att få upp mer information om dem. Vi
        vill understryka att om beskrivningen inte innehåller krav om färger och
        mönster så är bilden endast ett förslag och måste inte matchas. <br />
        Om ni har valt er present från listan så markera den som reserverad
        genom att klicka på knappen "Markera som reserverad", så att fler
        personer inte köper samma sak.
      </p>
      <ItemList />
    </div>
  );
}
