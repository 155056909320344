import React, { useEffect, useState } from "react";
import { produce } from "immer";
import axios from "axios";
import Dialog from "./dialog";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

export default function ItemList() {
  const [activeItem, setActiveItem] = useState("Sak1-1");
  const [list, setList] = useState([]);

  useEffect(() => {
    axios
      .get("/api/registry")
      .then(function (response) {
        setList(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    const eventSource = new EventSource("/api/registry/reservationUpdatesFeed");

    eventSource.onmessage = function (event) {
      const data = JSON.parse(event.data);

      for (const row of data) {
        const { categoryId, itemId, reserved } = row;

        setList(
          produce((draft) => {
            draft
              .find((category) => category.id === categoryId)
              .items.find((item) => item.id === itemId).reserved = reserved;
          })
        );
      }
    };

    eventSource.onerror = function (event) {
      console.log("Error occurred:", event);
    };
  }, [true]);

  function handleTitleClick(title) {
    setActiveItem(title !== activeItem ? title : "");
  }

  function handleReserveClick(categoryIndex, itemIndex, itemId, reserved) {
    if (!reserved) {
      //TODO: Prompt confirmation "Are you sure?"
    }

    setList(
      produce((draft) => {
        draft[categoryIndex].items[itemIndex].reserved = reserved;
      })
    );

    axios
      .post("/api/registry/updateReserved", { id: itemId, reserved })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div className="item-list">
      {list.map((category, categoryIndex) => {
        return (
          <ul className="item-list_category" key={category.title}>
            <h3>{category.title}</h3>
            {category.items.map((item, itemIndex) => {
              return (
                <li className="item-list_item" key={item.title}>
                  <button
                    className="item-list_item-title-button"
                    onClick={() => handleTitleClick(item.title)}
                    type="button"
                  >
                    <input type="checkbox" checked={item.reserved} readOnly />
                    <span>{item.title}</span>
                  </button>
                  {activeItem === item.title && (
                    <div className="item-list_item-extended">
                      <div className="item-list_item-image-container">
                        <PhotoProvider>
                          <PhotoView src={item.image}>
                            <img
                              src={item.image}
                              alt={item.title}
                              className="item-list_item-image"
                            />
                          </PhotoView>
                        </PhotoProvider>
                      </div>
                      <div>
                        <p className="item-list_item-description">
                          {item.description}
                        </p>
                        {!item.reserved ? (
                          <button
                            className="item-list_reservation-button"
                            onClick={() =>
                              handleReserveClick(
                                categoryIndex,
                                itemIndex,
                                item.id,
                                !item.reserved
                              )
                            }
                            type="button"
                          >
                            Markera som reserverad
                          </button>
                        ) : (
                          <Dialog
                            triggerButtonText="Ta bort reservation"
                            triggerButtonClassName="item-list_reservation-button"
                            description="Är du säker på att du vill ta bort reservationen?"
                            buttons={[
                              {
                                text: "Ja",
                                onClick: () =>
                                  handleReserveClick(
                                    categoryIndex,
                                    itemIndex,
                                    item.id,
                                    !item.reserved
                                  ),
                              },
                              { text: "Avbryt" },
                            ]}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        );
      })}
    </div>
  );
}
