import React from "react";
import { Field, ErrorMessage } from "formik";

export function MyField({ name, type, label, disabled }) {
  return (
    <div>
      <label>
        <p>{label}</p>
        <Field name={name} type={type} disabled={disabled} />
      </label>
      <ErrorMessage name={name} />
    </div>
  );
}

export function MyTextArea({ name, label, disabled }) {
  return (
    <div>
      <label>
        <p>{label}</p>
        <Field name={name} as="textarea" disabled={disabled} />
      </label>
      <ErrorMessage name={name} />
    </div>
  );
}
